import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {useEffect, useId} from "react";
import {useNavigate} from "react-router-dom";
import {getFeatured} from "../store/thunks/featuredThunk";
import {initPayment, setPromotion, setProvider, setStepper} from "../store/slices/appointmentSlice";
import {STEPPERS} from "../utils/Constants";
import {getByServices} from "../store/thunks/servicesThunk";
import {checkAddress} from "../store/thunks/AddressThunk";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
export const CarouselServices = () => {
    const userId = useId()
    const navigate = useNavigate();
    const {featured} = useAppSelector((state: RootState) => state.featured);
    const {typeId, searchText, departmentId} = useAppSelector((state: RootState) => state.filter);
    const dispatch = useAppDispatch()
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = () => {
        dispatch(getByServices(1, searchText, typeId, departmentId))
    }
    const onCard = (item: any) => {
        // dispatch(setStepper({stepper: STEPPERS.CALENDAR}))
        // dispatch(setProvider({provider: item}));
        // dispatch(initPayment({id: userId, independent: item.independent}));
        dispatch(checkAddress())
        dispatch(setPromotion({promotion: {}}));
        return navigate(`serviceDetail/${item.id}`);
        // navigate(`appointment/${item.id}`);
    }
    return <div className={'row'}>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container carousel-change"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
                {featured?.map((item, index) => (
                    <div key={index} className="col-sm-6 col-lg-4 col-xl-3 p-1"  style={{ width: "95%", height: "100%" }} onClick={() => onCard( item)}>
                        <div className="card shadow   h-400px">
                            <div className="card-img-overlay d-flex flex-column p-2 z-index-1">
                                <div><span className="badge text-bg-dark"><i
                                    className="bi bi-star-fill text-warning me-2"></i>{item.votes}</span></div>
                            </div>
                            <img src={item.resize_image}
                                 className="card-img-top"
                                 alt="course image" />
                            <div className="card-body pb-0">

                                <div className="d-flex justify-content-between mb-2">
                                    <p className="text-danger"> {item.name}</p>
                                    {/*<a href="#" className="badge bg-purple bg-opacity-10 text-purple">All*/}
                                    {/*    level</a>*/}

                                    {/*<a href="#" className="text-danger"><i*/}
                                    {/*    className="fas fa-heart"></i></a>*/}
                                </div>
                                <p className="text-truncate-2 mb-2">{item.price}</p>
                                <p className="text-truncate-2 mb-2">{item.description}</p>
                            </div>
                            <div className="card-footer pt-0 pb-3">
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle">
                                        <i className="fas fa-calendar"></i></div>
                                    <div
                                        className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle">
                                        <i className="fas fa-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </Carousel>;
    </div>
}
