import {useEffect, useId, useState} from "react";
import {Footer} from "../layout/footer";
import {HeaderAppointment} from "../layout/headerAppointment";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {STEPPERS} from "../utils/Constants";
import {
  initPayment,
  setCounterAddrress, setLoading, setPromotion,
  setProvider, setReloadAddress, setServices,
  setShowModalAddrress,
  setStepper
} from "../store/slices/appointmentSlice";
import {useNavigate, useParams} from "react-router-dom";
import {Breadcrumb} from "../compoments/Breadcrumb";
import {Banners} from "../compoments/Banners";
import {About} from "../compoments/About";
import {Appointment} from "../compoments/Appointment";
import {checkServicesAssigned} from "../core/calendar/checkServicesAssigned";
import {deleteAppointmentByIds, getAppointments, onCalendar, onConfirm} from "../store/thunks/appointmentThunk";
import {Services} from "../compoments/Services";
import { Modal } from "react-bootstrap";
import {getCheckStepperCondition} from "../core/calendar/getCheckStepperCondition";
import {getNextStepper} from "../core/calendar/getNextStepper";
import {setShowModal} from "../store/slices/authSlice";
import {getHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";

export const AppointmentPage = () => {
  const userId = useId()
  const { id,independent, promoId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [userProvider, setUserProvider] = useState({} as any);
  const {status, showModal, session} = useAppSelector((state: RootState) => state.auth);
  const {stepper, provider, payment, showModalAddrress, counterAddrress, reloadAddress, isLoading} = useAppSelector((state: RootState) => state.appointment) as any;
  const {confirmSchedule, qr} = useAppSelector((state: RootState) => state.confirm)
  useEffect(() => {
    (async () => await loadDataCheck())();
  }, [status, reloadAddress]);
  useEffect(() => {
    (async () => await loadData())();
  }, []);
  const loadDataCheck = async () => {
    console.log(' reload')
    if (status == 'authenticated' || reloadAddress != '') {
      await  checkAddress()
    }
  }
const loadData = async () => {
    dispatch(setLoading({loading: true}));
  dispatch(setStepper({stepper: STEPPERS.CALENDAR}))
  dispatch(setProvider({provider: {id: id, independent: independent}}));
  dispatch(initPayment({id: userId, independent: independent}));
  await loadServices(id, promoId);

  if (Number(promoId) == 0) {
    dispatch(setPromotion({promotion: {}}));
  } else {
    await getPromotion(promoId)
  }
  await getProvider(id, independent);
}
const loadServices = async (userId: string | undefined, promotionId: string | undefined) => {
  const promIdTemp = Number(promotionId) > 0 ? promotionId : 0;
  const params = {
    page: 1,
    userId: userId,
    typeId: 0,
    promoId: promIdTemp,
  };
  const {response, success} = await getHttp(buildUrlClient('servicesFilter'), params)
  if (success) {
    dispatch(setServices({services: response}));
  } else {
    dispatch(setServices({services: []}));
  }
  dispatch(setLoading({loading: false}));
}
const getPromotion = async (promotionId: string | undefined) => {
    const params = {
      id: promotionId
    }
  const {response, success} = await getHttp(buildUrlClient(`promotions/${promotionId}`), params);
  if (success) {
    dispatch(setPromotion({promotion: {
            id: response?.id,
            name: response.name,
            description: response.description,
            date_end: response.date_end,
            status: response.status,
            discount_percentage: response.discount_percentage}}));
  }
}
const getProvider = async (userId: string | undefined, independent: string | undefined) => {
const params = {
  id: userId,
  independent : independent
};
  const {response, success} = await getHttp(buildUrlClient('getProvider'), params);
  if (success) {
    setUserProvider(response);
    // dispatch(setProvider({provider: {id: id, independent: independent, perfilLink: response.p}}));
    // dispatch(setCounterAddrress({counterAddrress: response.count}));
  }
}
const checkAddress = async () => {
    console.log('sss');
  const {response, success} = await getHttp(buildUrlClient('checkAddress'));
  if (success) {
    dispatch(setCounterAddrress({counterAddrress: response?.count}));
    if (reloadAddress !== '') {
      dispatch(setReloadAddress({reloadAddress: ''}));
    }
  }
}
  const onNext =async () => {
  if (counterAddrress > 0) {
    const stepperCondition = await getCheckStepperCondition(stepper,payment);
    if (stepperCondition) {
      const next = await getNextStepper(stepper);
      switch (stepper) {
        case STEPPERS.CALENDAR:
          dispatch(setLoading({loading: true}));
          dispatch(onCalendar(payment,next));
          return;
        case STEPPERS.PAYMENT:
          dispatch(setLoading({loading: true}));
          dispatch(onConfirm(payment,next));
          return;
      }
    } else {
      alert('Asigne las horas a los servicios');
    }
  } else {
    dispatch(setShowModalAddrress({showModalAddrress: true}))
    alert('Registre su direccion')
  }
  }

  const onShowModal = () => {
    const {counters, appointments} = payment;
    if (status == 'authenticated') {
      if (counters?.length > 0) {
        dispatch(setStepper({stepper: STEPPERS.CALENDAR}));
        setShow(true);
      } else {
        alert(" Seleccione por lo menos un servivio ");
      }
    } else {
      dispatch(setShowModal({showModal: true}));
    // alert('Inicie Sesión')
    }
  }
  const onClose = () => {
    const {appointments} = payment;
    if (confirmSchedule) {
      setShow(false);
     return  navigate('/');
    } else {
      const ids = appointments?.map((item: any) => item.appointment_id).toString();
      if (ids != '' ) {
        // dispatch(deleteAppointmentByIds(ids));
      }
      setShow(false);
    }
  }
  return <>
    <HeaderAppointment />
      <div className="container">
        <Breadcrumb />
        <section className="pt-0">
          <div className="container-fluid px-0">
            <Banners />
          </div>
          <div className="container mt-n4">
            <div className="row">
              <div className="col-12">
                <div className="card bg-transparent card-body p-0">
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto mt-4 mt-md-0">
                      <div className="avatar-app avatar-xxl-app mt-n3">
                        {userProvider && <img className="avatar-img rounded-circle border border-white border-3 shadow"
                                              src={userProvider?.perfilLink} alt="" /> }
                      </div>
                    </div>
                    <div className="col d-md-flex justify-content-between align-items-center mt-4">
                      <div>
                        <h1 className="my-1 fs-4">{userProvider?.name} {userProvider?.last_name}<i
                            className="bi bi-patch-check-fill text-info small"></i></h1>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item h6 fw-light me-3 mb-1 mb-sm-0">
                            <i
                              className="fas fa-table text-warning me-2"></i>{ provider?.types}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row g-4 g-sm-5" style={{height: '100%'}}>
          <div className="col-xl-8 mb-4 mb-sm-0 ">
            <div className="card card-body shadow h-100 p-4">
              <div className="row g-3 mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <h4 >Agendar el servicio {showModalAddrress}</h4>
                  <div className="mt-2 mt-sm-0">
                    <button className="btn btn-success mb-0" onClick={() => onShowModal()} >Siguiente</button>
                  </div>
                </div>
                <div className="col-12">
                  <Services userId={id} promotionId={promoId}/>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button className="btn btn-success mb-0" type="button" onClick={() => onShowModal()}>Siguente</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 push-sm-12">
            <div className="row mb-0" style={{height: '100%'}}>
              <div className="col-md-12 col-xl-12">
                <div className="card card-body shadow mb-4">
                  <h6 className="fw-normal mb-0">Descripción del profesional</h6>
                    <hr/>
                  <About userId={id} independent={independent} />
                    <hr />

                  {show && <Modal
                      backdrop="static"
                      keyboard={false}
                      size={'xl'}
                      show={show}
                      onHide={() => onClose()}
                      dialogClassName="modal-100W"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        Creando la cita.
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Appointment />
                    </Modal.Body>
                    <Modal.Footer>
                      {!confirmSchedule &&
                          <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-success mb-0" type="button" disabled={isLoading} onClick={() => onNext()}>Siguente</button>
                          </div>}
                    </Modal.Footer>
                  </Modal>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
  </>
}
