import {Header} from "../layout/header";
import {Welcome} from "../compoments/Welcome";
import {Types} from "../compoments/Types";
import {FeaturedAll} from "../compoments/FeaturedAll";

export const ServicesPage = () => {

    return <>
        <Header />
        <div className={'container'}>
            <Welcome />
        </div>
        <div className={'container'}>
            <Types />
        </div>
        <div className={'container'}>
        <nav  aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href={'/'}>Inicio</a></li>
                <li className="breadcrumb-item active" aria-current="page">Destacados</li>
            </ol>
        </nav>
        </div>
        <div className={'container'}>
            <FeaturedAll />
        </div>
    </>
}
