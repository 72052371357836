import React, {useEffect, useState} from "react";
import {HeaderAppointment} from "../layout/headerAppointment";
import {Breadcrumb} from "../compoments/Breadcrumb";
import {Banners} from "../compoments/Banners";
import {Modal} from "react-bootstrap";
import {Appointment} from "../compoments/Appointment";
import {Footer} from "../layout/footer";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {getHttp} from "../api/HttpClient";
import {useParams} from "react-router-dom";
import {ServiceProviderModel} from "../models/ServiceProviderModel";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {
  initPayment,
  setCounters,
  setLoading,
  setProvider,
  setServiceProvider, setShowModalAddrress,
  setStepper
} from "../store/slices/appointmentSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {RootState} from "../store/store";
import * as Constants from "constants";
import {authStatus, STEPPERS} from "../utils/Constants";
import {Counter} from "../models/Counter";
import {getCheckStepperCondition} from "../core/calendar/getCheckStepperCondition";
import {getNextStepper} from "../core/calendar/getNextStepper";
import {onCalendar, onConfirm} from "../store/thunks/appointmentThunk";
import {setShowModal} from "../store/slices/authSlice";

export const ServiceDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  // const [confirmSchedule, setConfirmSchedule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState({} as any);
  const [professionalSelected, setProfessionalSelected] = useState(null);
  const {status, showModal, session} = useAppSelector((state: RootState) => state.auth);
  const {serviceProvider,stepper, provider, payment, showModalAddrress, counterAddrress, reloadAddress} = useAppSelector((state: RootState) => state.appointment) as any;
  const {confirmSchedule, qr} = useAppSelector((state: RootState) => state.confirm)
  useEffect(() => {
    (async () => await loadData())();
  }, []);
  const loadData = async () => {
    const params = {
      // date: dateSelected
    }
    const { response, success } = await getHttp(buildUrlClient(`serviceHotelById/${id}`), params);
    if (success) {
      setItem(response);
      const newList = response?.professionals.map((item: any, index: number) => {
        return {
          ...item,
          selected: index == 0 ? true :  false,
        };
      })
      const profSelected = newList.find((it: any) => it.selected == true);
      setProfessionalSelected(profSelected);
      const serviceProviderModel: ServiceProviderModel = {
        id: id,
        professionals: newList,
        counter: 1,
      };
      dispatch(setServiceProvider({serviceProvider: serviceProviderModel}));
    }
  }
  const onClose = () => {
    const {appointments} = payment;
    if (confirmSchedule) {
      setShow(false);
      // return  navigate('/');
    } else {
      const ids = appointments?.map((item: any) => item.appointment_id).toString();
      if (ids != '' ) {
        // dispatch(deleteAppointmentByIds(ids));
      }
      setShow(false);
    }
  }
  const onNext = async () => {
    if (counterAddrress > 0) {
      const stepperCondition = await getCheckStepperCondition(stepper,payment);
      if (stepperCondition) {
        const next = await getNextStepper(stepper);
        switch (stepper) {
          case STEPPERS.CALENDAR:
            dispatch(setLoading({loading: true}));
            dispatch(onCalendar(payment,next));
            return;
          case STEPPERS.PAYMENT:
            dispatch(setLoading({loading: true}));
            dispatch(onConfirm(payment,next));
            return;
        }
      } else {
        alert('Asigne las horas a los servicios');
      }
    } else {
      dispatch(setShowModalAddrress({showModalAddrress: true}))
      alert('Registre su direccion')
    }
  }
  const onShowModal =async () => {
    if (serviceProvider) {
      if (status == authStatus.authenticated) {
        const professionalSelected: any = serviceProvider.professionals.find((item: any) => item.selected == true);
        if (professionalSelected) {
          dispatch(setStepper({stepper: STEPPERS.CALENDAR}))
          dispatch(setProvider({provider: {id: professionalSelected.id, independent: professionalSelected.independent}}));
          dispatch(initPayment({id: professionalSelected.id, independent: professionalSelected.independent}));

          const yep: Counter = {serviceId: professionalSelected.service_id, typeId:  professionalSelected.service_id, count: serviceProvider.counter};
          dispatch(setCounters({counters: [yep]}));

          setShow(true);
          // const serviceModel: any = {
          //   id: professionalSelected.service_id,
          //   name: "",
          //   price: item.price,
          //   counter: serviceProvider.counter
          // }
          // // let schedules = await getSchedules(professionalSelected?.schedules)
          // const listServices = [];
          // listServices.push(serviceModel);
          // const professionalModel: any = {
          //   id: professionalSelected.id,
          //   name: professionalSelected.name,
          //   independent: professionalSelected.independent,
          //   lastName: professionalSelected.last_name,
          //   latitude: professionalSelected.latitude,
          //   longitude: professionalSelected.longitude,
          //   modelId: professionalSelected.modelId,
          //   perfilLink: professionalSelected.perfilLink,
          //   services: listServices,
          //   schedules: []
          // }
          // dispatch(setProfessional({professional: professionalModel}));
        }
      } else {
        dispatch(setShowModal({showModal: true}));
      }

    }

// const prodSelected =

  }
  const onMinus = (count: number) => {
    if (count >=1) {
      onChange(count);
    }
  }
  const onChange = (value: number) => {
    if (serviceProvider){
      dispatch(setServiceProvider({serviceProvider: {...serviceProvider, counter: value}}));
    }
  };
  const onAdd = (count: number) => {
    if (count >=1 && count < 20) {
      onChange(count);
    }
  }
  const selected = (pro: any) => {
    let listNew  = [];
    for (let pro of serviceProvider.professionals) {
      if (pro.id == item.id) {
        listNew.push({...pro, selected: true});
        setProfessionalSelected({...pro, selected: true});
      } else {
        listNew.push({...pro, selected: false});
      }
    }
    dispatch(setServiceProvider({serviceProvider: {...serviceProvider, professionals: listNew}}))
  }
  return <>
    <HeaderAppointment />
    <div className="container">
      <div className="container-fluid px-0">
        <Banners />
      </div>
      <Breadcrumb />
      <section className="pt-5">
        <div className="container" data-sticky-container>
          <div className="row g-4 g-sm-5">
            <div className="col-xl-4">
              <div data-sticky data-margin-top="80" data-sticky-for="992">
                <div className="row justify-content-center">
                  <div className="col-md-8 col-xl-12">
                    <div className="card shadow">
                      <div className="rounded-3">
                        <img src={item?.original_image} className="card-img-top"/>
                      </div>
                      <div className="card-body pb-3">
                        <div className="text-center">
                          {/*<a href="#" className="btn btn-success-soft mb-2 mb-sm-0 me-00 me-sm-3"><i*/}
                          {/*    className="bi bi-cart3 me-2"></i>Add to Cart</a>*/}

                            <button className="btn btn-danger-soft  btn-round me-1 mb-1 mb-md-0 " onClick={() => onMinus(serviceProvider.counter - 1)}>
                              <FontAwesomeIcon icon={faMinus} />
                            </button>
                            <button className="btn btn-secondary-soft  btn-round me-1 mb-1 mb-md-0" disabled={true}>
                              <p>{serviceProvider.counter}</p>
                            </button>
                            <button className="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" onClick={() => onAdd(serviceProvider.counter + 1)}>
                              <FontAwesomeIcon icon={faPlus} />
                            </button>

                          {/*<a href="#" className="btn btn-light mb-0"><i className="bi bi-bookmark me-2"></i>Add wishlist</a>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <h1 className="mb-4">{item.name}</h1>
              <div className="d-flex align-items-center mb-4">
                {/*<h2 className="me-3 mb-0">4.5</h2>*/}
                {/*<div>*/}
                {/*  <ul className="list-inline mb-0">*/}
                {/*    <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                {/*    <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                {/*    <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                {/*    <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                {/*    <li className="list-inline-item me-0 small"><i className="fas fa-star-half-alt text-warning"></i>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*  <p className="mb-0 small mt-n1">Reviews from our buyers</p>*/}
                {/*</div>*/}
              </div>
              <ul className="list-inline mb-4">
                <li className="list-inline-item">
                  <input type="radio" className="btn-check" name="options" id="option1" checked />
                    <label className="btn btn-success-soft-check" htmlFor="option1">
                      {/*<span className="mb-2 h6 fw-light">Paperback</span>*/}
                      <span className="d-flex align-items-center">
								<span className="mb-0 h5 me-2 text-success">Bs {item?.price}</span>
								{/*<span className="text-decoration-line-through fs-6 mb-0 me-2">$350</span>*/}
								{/*<span className="badge text-bg-dark mb-0">60% off</span>*/}
							</span>
                    </label>
                </li>

              </ul>
              <h4>Descripción</h4>
              <p>{item.description}</p>
              <h4>Profesionales</h4>
              <div className="row g-4 mt-4">
                {serviceProvider?.professionals?.map((pro: any, index: number) => (
                    <div className="col-sm-4" key={index} onClick={() => selected(pro)}>
                      <div className="card shadow h-100">
                        <img src={pro.perfilLink} className="card-img-top" alt="course image"/>
                        <div className="card-body pb-0">
                          <div className="d-flex justify-content-between mb-2">
                            {/*<a href="#" className="badge bg-purple bg-opacity-10 text-purple">All level</a>*/}
                            {/*<a href="#" className="h6 fw-light mb-0">*/}
                            {/*  <i className="far fa-heart">*/}

                            {/*  </i>*/}
                            {/*</a>*/}
                            <div className="form-check radio-bg-light me-4">

                            </div>
                          </div>
                          <h5 className="card-title fw-normal"><a href="#">{pro.name}</a></h5>
                          <p className="mb-2 text-truncate-2">{pro.description}</p>
                          {/*<ul className="list-inline mb-0">*/}
                          {/*  <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                          {/*  <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                          {/*  <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                          {/*  <li className="list-inline-item me-0 small"><i className="fas fa-star text-warning"></i></li>*/}
                          {/*  <li className="list-inline-item me-0 small"><i className="far fa-star text-warning"></i></li>*/}
                          {/*  <li className="list-inline-item ms-2 h6 fw-light mb-0">4.0/5.0</li>*/}
                          {/*</ul>*/}
                        </div>
                        <div className="card-footer pt-0 pb-3">
                          <hr/>
                          <div className="d-flex justify-content-between">
                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                   id="flexRadioDefault1" checked />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                              Seleccionar
                            </label>
                            {/*<span className="h6 fw-light mb-0"><i className="far fa-clock text-danger me-2"></i>12h 56m</span>*/}
                            {/*<span className="h6 fw-light mb-0"><i className="fas fa-table text-orange me-2"></i>15 lectures</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
              <br/>
              <div className="d-sm-flex justify-content-between align-items-center">
                <div className="mt-2 mt-sm-0">
                  <button className="btn btn-success mb-0" onClick={() => onShowModal()} >Siguiente</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-md-12 col-xl-12">
        {show && <div className="card card-body shadow mb-4">
           <Modal
              backdrop="static"
              keyboard={false}
              size={'xl'}
              show={show}
              onHide={() => onClose()}
              dialogClassName="modal-100W"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Creando la cita.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Appointment />
            </Modal.Body>
            <Modal.Footer>
              {!confirmSchedule &&
                  <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-success mb-0" type="button" disabled={isLoading} onClick={() => onNext()}>Siguente</button>
                  </div>}
            </Modal.Footer>
          </Modal>
        </div>
          }
      </div>
    </div>
    <Footer/>
  </>
}
